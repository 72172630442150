@use "~@mozilla-protocol/core/protocol/css/includes/lib" as *;
@use "../../styles/color";

.entry {
  button {
    @include text-body-xl;
    background-color: transparent;
    border-style: none;
    display: flex;
    align-items: center;
    gap: $spacing-md;
    justify-content: space-between;
    cursor: pointer;
    padding: $spacing-lg $spacing-md;
    width: 100%;
    border-bottom: 1px solid $color-light-gray-30;
    text-align: start;

    &:focus {
      outline: none;
      border-radius: $border-radius-md;
      border-color: $color-light-gray-10;
      background-color: $color-light-gray-10;
      color: $color-blue-50;
    }
  }

  &:first-child button {
    border-top-style: none;
  }

  dd {
    padding: $spacing-lg $spacing-md;
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
  }

  &.is-collapsed {
    dd {
      display: none;
    }
  }

  .plus-icon {
    flex: 0 0 auto;
    transition: transform 0.2s ease-out;
    color: $color-blue-50;
  }

  &.is-expanded {
    .plus-icon {
      transform: rotate(45deg);
    }
  }
}
